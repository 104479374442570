/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { cpf } from 'cpf-cnpj-validator';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container } from './styles';
import CreditCard from '../CreditCard';
import Toast from '~/utils/toast';
import { useAuth } from '~/hooks/Auth';

interface IFormData {
  document: string;
  name: string;
  cardNumber: string;
  expiration: string;
  cvc: string;
  email: string;
  phone: string;
  birthdate: string;
  gender: string;
  zipcode: string;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  complement: string;
}

interface IAddCards {
  saveCard?: boolean;
  onSucceededAddCard?(card?: any): void;
}

const AddCards: React.FC<IAddCards> = ({
  saveCard,
  onSucceededAddCard,
  children,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [save, setSave] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { user, updateUser } = useAuth();

  const handleSubmit = useCallback(
    async (data: IFormData) => {
      try {
        setDisabled(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          document: Yup.string()
            .test('cpf', 'CPF inválido', (value) =>
              cpf.isValid(value as string)
            )
            .required('O CPF é obrigatório'),
          name: Yup.string()
            .test(
              'name',
              'O nome não pode conter números ou caracteres especiais',
              (value) => /^[a-zA-Z\s]*$/.test(value as string)
            )
            .required('O nome é obrigatório'),
          cardNumber: Yup.string().required('O número é obrigatório'),
          expiration: Yup.string().required('A validade é obrigatória'),
          cvc: Yup.string().required('O CVC é obrigatório'),
          email: Yup.string().required('O e-mail é obrigatório'),
          phone: Yup.string().required('O celular é obrigatório'),
          gender: Yup.string().when(`$checkGender`, {
            is: (checkGender: boolean) => checkGender,
            then: Yup.string().required('O gênero é obrigatório'),
            otherwise: Yup.string(),
          }),
          birthdate: Yup.string().when(`$checkBirthdate`, {
            is: (checkBirthdate: boolean) => checkBirthdate,
            then: Yup.string().required('O nascimento é obrigatório'),
            otherwise: Yup.string(),
          }),
          zipcode: Yup.string().when(`$checkZipCode`, {
            is: (checkZipCode: boolean) => checkZipCode,
            then: Yup.string().required('O CEP é obrigatório'),
            otherwise: Yup.string(),
          }),
          street: Yup.string().when(`$checkStreet`, {
            is: (checkStreet: boolean) => checkStreet,
            then: Yup.string().required('O endereço é obrigatório'),
            otherwise: Yup.string(),
          }),
          number: Yup.string().when(`$checkNumber`, {
            is: (checkNumber: boolean) => checkNumber,
            then: Yup.string().required('O número é obrigatório'),
            otherwise: Yup.string(),
          }),
          neighborhood: Yup.string().when(`$checkNeighborhood`, {
            is: (checkNeighborhood: boolean) => checkNeighborhood,
            then: Yup.string().required('O bairro é obrigatório'),
            otherwise: Yup.string(),
          }),
          city: Yup.string().when(`$checkCity`, {
            is: (checkCity: boolean) => checkCity,
            then: Yup.string().required('A cidade é obrigatório'),
            otherwise: Yup.string(),
          }),
          state: Yup.string().when(`$checkState`, {
            is: (checkState: boolean) => checkState,
            then: Yup.string().required('O CEP é obrigatório'),
            otherwise: Yup.string(),
          }),

          complement: Yup.string(),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            checkBirthdate: !user.additionalInformation.birthdate,
            checkGender: !user.additionalInformation.gender,
            checkZipCode: !user.additionalInformation.address?.zip_code,
            checkStreet: !user.additionalInformation.address?.street,
            checkNumber: !user.additionalInformation.address?.number,
            checkNeighborhood:
              !user.additionalInformation.address?.neighborhood,
            checkCity: !user.additionalInformation.address?.city,
            checkState: !user.additionalInformation.address?.state,
          },
        });

        const cardElement = document.getElementById('card');

        let flag = 'unknown';
        if (cardElement) {
          flag =
            cardElement.children[0].children[0].children[0].classList[1].replace(
              'ReactCreditCard--',
              ''
            );
        }

        let card_id = new Date().getTime();

        if (saveCard || save) {
          const formData = {
            document: data.document,
            name: data.name,
            number: data.cardNumber,
            due_date: data.expiration,
            flag,
            cvc: data.cvc,
            email: data.email,
            phone: data.phone,
            zipcode: data.zipcode,
            street: data.street,
            houseNumber: data.number,
            neighborhood: data.neighborhood,
            city: data.city,
            state: data.state,
            complement: data.complement,
          };

          const response = await api.post('cards/users', formData);
          card_id = response.data.id;
        }

        const addressFormData = {
          zip_code: data.zipcode,
          street: data.street,
          number: data.number,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
          complement: data.complement,
        };

        let address_id = user.additionalInformation.address?.id;
        if (!user.additionalInformation.address) {
          const response = await api.post(`addresses`, addressFormData);
          address_id = response.data.id;
        }

        if (!user.additionalInformation.document) {
          const athleteFormData = {
            user_id: user.id,
            address_id: address_id || undefined,
            document: data.document,
            phone: data.phone,
            gender: data.gender,
          };
          await api.put(
            `additional-informations/${user.additionalInformation.id}`,
            athleteFormData
          );
        }

        updateUser({
          ...user,

          additionalInformation: {
            ...user.additionalInformation,

            document: data.document,
            phone: data.phone,
            gender: data.gender || user.additionalInformation.gender,
            birthdate: data.birthdate || user.additionalInformation.birthdate,
            address: {
              ...user.additionalInformation.address,
              id: address_id,
              zip_code: data.zipcode || '',
              street: data.street || '',
              number: data.number,
              neighborhood: data.neighborhood || '',
              state: data.state || '',
              city: data.city || '',
              complement: data.complement || '',
              country: 'BR',
            },
          },
        });

        Toast.fire({
          icon: 'success',
          iconColor: '#ff6900',
          title: 'Cartão adicionado com sucesso!',
        });

        if (onSucceededAddCard) {
          onSucceededAddCard(
            !saveCard
              ? {
                  id: card_id,
                  card_id,
                  name: data.name,
                  number: data.cardNumber,
                  due_date: data.expiration,
                  flag,
                  cvc: data.cvc,
                  email: data.email,
                  phone: data.phone,
                  zipcode: data.zipcode,
                  street: data.street,
                  houseNumber: data.number,
                  neighborhood: data.neighborhood,
                  city: data.city,
                  state: data.state,
                  complement: data.complement,
                }
              : undefined
          );
        }
        setDisabled(false);
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setDisabled(false);
        } else {
          setDisabled(false);
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [onSucceededAddCard, save, saveCard, updateUser, user]
  );
  return (
    <Container>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-11 text-center pt-3">
            {children}
            <div className="banner my-3" />
            <h1 className="text-start mb-3">Novo cartão para pagamentos</h1>
            <p>
              Você esta adicionando um novo cartão para pagamentos. Preencha os
              dados abaixo para salvá-lo para pagamentos futuros
            </p>
          </div>

          <div className="col-11 px-3">
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              className="d-flex flex-column mb-5 pb-5"
            >
              <CreditCard saveCard={saveCard} setSave={setSave} />
              <button
                type="submit"
                disabled={disabled}
                className="btn btn-login h6 fw-normal py-2 mt-4"
              >
                Adicionar cartão
              </button>
            </Form>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AddCards;

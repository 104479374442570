import React, { useCallback, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { MdOutlineRefresh } from 'react-icons/md';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import axios from 'axios';
import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import { Container } from './styles';
import ButtonBack from '~/components/ButtonBack';
import InputCode from '~/components/InputCode';
import Success from '~/components/Success';
import { IUserData } from '..';
import Toast from '~/utils/toast';

interface IFormData {
  code: string;
}

interface IVerifyAccount {
  onClickStart(): void;
  verify: boolean;
  userData?: IUserData;
}

const VerifyAccount: React.FC<IVerifyAccount> = ({
  onClickStart: handleClickStart,
  verify: verifyData,
  userData,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [verify, setVerify] = useState(verifyData);
  const [errorCode, setErrorCode] = useState('');

  const handleSubmit = useCallback(async (data: IFormData) => {
    try {
      formRef.current?.setErrors({});
      setErrorCode('');

      const schema = Yup.object().shape({
        code: Yup.string().required('O código é obrigatório'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await api.post('users/sessions/verify-account', {
        code: data.code,
      });

      setVerify(false);
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errors = getValidationErros(error);
        formRef.current?.setErrors(errors);
        if (errors.code) {
          setErrorCode(errors.code);
        }
      } else if (axios.isAxiosError(error)) {
        setErrorCode('Código inválido');
      } else {
        Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
      }
    }
  }, []);

  const handleClickResendCode = useCallback(async () => {
    await api.post('users/sessions/resend-code', {
      email: userData?.email,
    });

    Toast.fire({
      icon: 'success',
      iconColor: '#ff6900',
      title: 'Um novo código foi enviado a seu e-mail!',
    });
  }, [userData]);

  return (
    <Container className="container">
      {verify ? (
        <div className="row justify-content-center">
          <div className="col-11 text-center pt-3">
            <ButtonBack className="mt-3" />
            <div className="banner my-3" />
            <h1 className="text-start mb-3">Verifique sua conta</h1>
            <p>Insira abaixo o código PIN que enviamos para o e-mail.</p>
          </div>

          <div className="col-11 px-3">
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              className="d-flex flex-column pb-3"
            >
              <label className="my-4">
                <InputCode name="code" error={errorCode} />
              </label>

              <button
                type="button"
                className="d-flex align-items-center justify-content-center btn btn-resend mb-3 h6 fw-normal py-2 mt-3"
                onClick={handleClickResendCode}
              >
                <MdOutlineRefresh
                  size={14}
                  color="#ff6900"
                  className="g-icon me-1"
                />
                Reenviar
              </button>

              <button
                type="submit"
                className="btn btn-login mb-3 h6 fw-normal py-2"
              >
                Verificar
              </button>
              {/* <p className="text-center">
                Se não estiver conseguindo confirmar seu numero,{' '}
                <Link to={`${process.env.PUBLIC_URL}/cadastro/confirmar`}>
                  clique aqui
                </Link>{' '}
                para tentar com outro número.
              </p> */}
            </Form>
          </div>
        </div>
      ) : (
        <div className="row flex-column justify-content-center align-items-center vh-100">
          <div className="col-10 text-center">
            <Success
              active={!verify}
              title={`Conta ${userData ? 'criada!' : 'verificada!'}`}
              text={`Sua conta foi ${
                userData ? 'criada' : 'validada'
              } com sucesso.<br>Bem-vindo à Koro! Prepare-se para vibrar e participar dos eventos esportivos que fazem seu coração bater mais forte.`}
              textButton="Começar"
              onBtnClick={handleClickStart}
            />
          </div>
        </div>
      )}
    </Container>
  );
};

export default VerifyAccount;

/* eslint-disable react/no-danger */
import React from 'react';
import Lottie from 'react-lottie';

import { Container } from './styles';

import success from '~/assets/animations/success.json';
import exclamation from '~/assets/animations/exclamation.json';

interface ISuccess {
  active: boolean;
  title?: string;
  paymentMethodId?: number;
  text: string;
  textButton?: string;
  onBtnClick?(): void;
}

const Success: React.FC<ISuccess> = ({
  active,
  title,
  text,
  paymentMethodId,
  textButton,
  onBtnClick,
}) => {
  return (
    <Container active={active}>
      <div className={`${paymentMethodId === -1 ? '' : 'anim-lottie'} mt-n5`}>
        <Lottie
          options={{
            animationData: paymentMethodId === -1 ? exclamation : success,
            autoplay: true,
            loop: paymentMethodId === -1,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          height={250}
          width={250}
        />
      </div>
      <h2 className={`${paymentMethodId === -1 ? 'mt-0' : 'mt-n5'} h2 fw-bold`}>
        {title}
      </h2>
      <p
        className="text-center my-4"
        dangerouslySetInnerHTML={{ __html: text }}
      />
      <button
        type="button"
        onClick={onBtnClick}
        className="btn btn-login mb-3 h6 fw-normal py-2 px-5"
      >
        {textButton}
      </button>
    </Container>
  );
};

export default Success;

import styled from 'styled-components';

interface IProps {
  active: boolean;
}

export const Container = styled.div<IProps>`
  min-height: 100vh;
  background-color: ${(props) => (props.active ? '#ff6900' : '#fff')};
  overflow-x: hidden;
  h2 {
    color: #ff6900;
    font-family: 'Poppins';
    img {
      width: 20px;
      height: 20px;
    }
  }

  label span {
    color: #8c8c8c;
    font-size: 13px;
    font-weight: 400;
  }

  h4 {
    color: #878787;
    line-height: normal;
    font-size: 14px;
    font-weight: 400;
  }

  p {
    color: #8c8c8c;
    font-size: 13px;
    font-weight: 400;
  }
  .credtcard {
    img {
      max-width: 32px;
    }

    h4 {
      color: #000;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 400;
      text-align: start;
    }
    p {
      color: #878787;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 400;
      text-align: start;
    }
  }

  .bd-input {
    border: 1px solid rgb(225, 225, 225);
    height: 37px;
    border-radius: 4px;
    width: 100% !important;
    input {
      color: #18191a;
    }
    svg {
      display: none;
    }
  }

  .btn-pix {
    height: 49px;
    h4 {
      color: #000;
      font-family: 'Roboto';
      font-size: 12px;
      font-weight: 400;
      text-align: start;
    }
  }

  .btn-back {
    color: #878787;
    font-family: 'Roboto';
    font-size: 12px;
    font-weight: 400;
  }

  .btn-addcard {
    color: #000;
    font-size: 14px;
    font-weight: 400;
  }

  .check label > div {
    margin-right: 16px;
  }

  .check span span {
    color: #292623;
  }

  .btn-login {
    border-radius: 4px;
    background-color: #ff6900;
    color: #fff;
  }

  .not-active {
    position: relative;
    width: 24px;
    height: 24px;
    border: 1px solid #e1e1e1;
    background-color: #fff;
    border-radius: 50px;
    transition: all 0.2s ease ease-in-out;
    ::before {
      content: '';
      position: absolute;
      left: 5px;
      top: 5px;
      width: 12px;
      height: 12px;
      border: 1px solid #8c8c8c;
      background-color: #fff;
      border-radius: 50px;
    }
  }

  .active {
    position: relative;
    width: 24px;
    height: 24px;
    border: 1px solid #ffb580;
    background-color: #ffb580;
    border-radius: 50px;
    transition: all 0.2s ease-in-out;
    ::before {
      content: '';
      position: absolute;
      left: 5px;
      top: 5px;
      width: 12px;
      height: 12px;
      border: 1px solid #ff842f;
      background-color: #ff842f;
      border-radius: 50px;
    }
  }

  .success {
    h2 {
      color: #fff;
    }

    p {
      color: #fff;
      font-size: 14px;
      font-weight: 400;
    }

    button {
      background-color: #fff;
      color: #ff6900;
    }
  }

  span.label {
    color: #292623;
    font-size: 12px;
    font-weight: 400;
  }

  .form-control {
    border-radius: 4px;
    border: 1px solid #e1e1e1;
    height: auto;
    opacity: 1;
    margin-bottom: 4px;
    input {
      color: #18191a;
    }
    svg {
      display: none;
    }
  }

  .check span {
    color: #18191a;
    font-size: 16px;
    font-weight: 400;
  }
`;

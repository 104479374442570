import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import Home from '~/pages/Home';

import Login from '~/pages/Login';
import Signup from '~/pages/Signup';
import RecoverPassword from '~/pages/RecoverPassword';
import ChangePassword from '~/pages/ChangePassword';
import Profile from '~/pages/Profile';
import SearchEvents from '~/pages/SearchEvents';
import Event from '~/pages/Event';
import CheckoutCategories from '~/pages/CheckoutCategories';
import CheckoutOwnership from '~/pages/CheckoutOwnership';
import CheckoutPayment from '~/pages/CheckoutPayment';
import MyTickets from '~/pages/MyTickets';
import Ticket from '~/pages/Ticket';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={Home} both />

      <Route path={`${process.env.PUBLIC_URL}/login`} component={Login} />
      <Route
        path={`${process.env.PUBLIC_URL}/cadastro`}
        exact
        component={Signup}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/perfil`}
        component={Profile}
        isPrivate
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/perfil/:slug`}
        component={Profile}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/cadastro/:slug`}
        component={Signup}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/recuperar-senha`}
        component={RecoverPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/alterar-senha/:token`}
        component={ChangePassword}
      />

      <Route
        path={`${process.env.PUBLIC_URL}/meus-ingressos/proximos`}
        exact
        component={MyTickets}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/meus-ingressos/realizados`}
        exact
        component={MyTickets}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/meus-ingressos/cancelados`}
        exact
        component={MyTickets}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/meus-ingressos/:slug`}
        component={Ticket}
        isPrivate
      />

      <Route
        path={`${process.env.PUBLIC_URL}/buscar-eventos`}
        component={SearchEvents}
        both
      />

      <Route
        path={`${process.env.PUBLIC_URL}/eventos/:slug`}
        component={Event}
        exact
        both
      />

      <Route
        path={`${process.env.PUBLIC_URL}/eventos/:slug/categorias`}
        component={CheckoutCategories}
        exact
        both
      />
      <Route
        path={`${process.env.PUBLIC_URL}/eventos/:slug/categorias/:preview`}
        component={CheckoutCategories}
        exact
        both
      />
      <Route
        path={`${process.env.PUBLIC_URL}/eventos/:slug/titularidade`}
        component={CheckoutOwnership}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/eventos/:slug/resumo`}
        component={CheckoutPayment}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/eventos/:slug/:preview`}
        component={Event}
        exact
        both
      />
    </Switch>
  );
};

export default routes;
